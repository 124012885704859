<template>
    <div class="cardCreate" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form @submit.native.prevent :inline="true" v-model="form" size="small" label-width="80px">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="机构名称">
                            <el-select v-model="form.deptCode" filterable>
                                <el-option value="" label="请选择" />
                                <el-option
                                    v-for="dept in meta.deptCodes"
                                    :label="dept.name"
                                    :value="dept.code"
                                    :key="dept.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="创建人">
                            <el-input v-model="form.creator" readonly />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="创建时间">
                            <el-date-picker v-model="form.createDate" type="date" value-format="yyyy-MM-dd" readonly />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <ef-remark v-model="form.remark" />
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button type="primary" @click="save" size="small">保存</el-button>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                border
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="520"
            >
                <el-table-column label="全选" type="selection" width="60" align="center" fixed="left" />
                <el-table-column label="序号" width="60" type="index" fixed="left" />
                <el-table-column label="会员卡号" width="240">
                    <template slot-scope="scope">
                        <el-input
                            v-model="scope.row.cardPrintNos"
                            @change="handleQueryCard(scope.row, scope.$index)"
                            size="mini"
                        />
                    </template>
                </el-table-column>
                <el-table-column label="会员卡类型" width="240" prop="cardType">
                    <template slot-scope="scope"
                        ><span>{{ scope.row.cardType | cardType }}</span></template
                    >
                </el-table-column>
                <el-table-column label="卡状态" width="120">
                    <template slot-scope="scope"
                        ><span>{{ scope.row.status | status }}</span></template
                    >
                </el-table-column>
                <el-table-column label="会员名称" width="120" prop="memberName" />
                <el-table-column label="会员编码" width="120" prop="memberCodes" v-if="false" />
                <el-table-column label="手机号" width="120" prop="mobile" />
                <el-table-column label="工本费" width="160">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.inWorkCosts" size="mini" />
                    </template>
                </el-table-column>
                <el-table-column label="付款方式" width="160">
                    <template slot-scope="scope">
                        <el-select v-model="scope.row.payTypeCode" v-if="scope.row.inWorkCosts" filterable size="mini">
                            <el-option value="" label="请选择" />
                            <el-option
                                v-for="payType in meta.payType"
                                :label="payType.template.name"
                                :key="payType.template.code"
                                :value="payType.template.code"
                            />
                        </el-select>
                    </template>
                </el-table-column>

                <el-table-column label="操作" width="145" header-align="center">
                    <template slot-scope="scope">
                        <el-button size="mini" type="danger" @click="deleteRow(scope.$index, tableData, scope.row)"
                            >删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
    </div>
</template>
<script>
import Util from '../../../../js/Util';
import UrlUtils from '../../../../js/UrlUtils';
import EfRemark from 'components/EfRemark';
import MoneyUtils from 'js/MoneyUtils';

export default {
    name: 'MemberCardCreat',
    components: { EfRemark },
    props: ['mobile', 'deptCode', 'deptGroupCode'],
    data() {
        return {
            form: {
                deptGroupCode: this.deptGroupCode,
                deptCode: this.deptCode,
                remark: '',
                createDate: new Date(),
                creator: this.$store.state.session.name,
                page: 1,
                limit: 100,
            },
            meta: {
                groups: [],
                deptCodes: [],
                payType: [],
            },
            loadingDeptGroupFlag: true,
            url: {
                queryCard: '/member/card/page',
                queryPayType: '/finance/pay/payType/page',
                queryMember: '/member/page',
                save: '/member/card/createBindCard',
            },
            areaData: {
                level: 1,
            },
            codes: [],
            tableData: [],
        };
    },
    mounted() {
        this.$efApi.deptApi.managedAllDepts().then((rst) => {
            this.meta.deptCodes = rst;
        });
        this.handleQueryMember();
        this.handleQueryPayType();
    },
    methods: {
        save() {
            const _this = this;
            if (_this.tableData.length == 0) {
                _this.$message.error('没有可以发卡的数据');
                return;
            }
            const mobileMap = new Map();
            const cardNoMap = new Map();
            for (let i = 0; i < _this.tableData.length; i++) {
                const v = _this.tableData[i];
                if (!v.cardPrintNos) {
                    _this.$message.error('请填写第【' + (i + 1) + '】行会员卡号');
                    return;
                }
                if (!v.memberCodes) {
                    _this.$message.error('第【' + (i + 1) + '】行未选择会员');
                    return;
                }
                if (Number(v.inWorkCosts) > 0 && !v.payTypeCode) {
                    _this.$message.error('第【' + (i + 1) + '】行未选择付款方式');
                    return;
                }
                if (mobileMap.get(v.memberCodes)) {
                    const temIndex = mobileMap.get(v.memberCodes);
                    _this.$message.error('第【' + (i + 1) + '】行的会员与第【' + temIndex + '】行的会员重复');
                    return;
                } else {
                    mobileMap.set(v.memberCodes, '' + (i + 1));
                }
                if (cardNoMap.get(v.cardPrintNos)) {
                    const temIndex = cardNoMap.get(v.cardPrintNos);
                    _this.$message.error('第【' + (i + 1) + '】行的卡号与第【' + temIndex + '】行的卡号重复');
                    return;
                } else {
                    cardNoMap.set(v.cardPrintNos, '' + (i + 1));
                }
            }
            const _params = {
                cardPrintNos: _this.tableData.map((d) => d.cardPrintNos || ' '),
                memberCodes: _this.tableData.map((d) => d.memberCodes || ' '),
                inWorkCosts: _this.tableData.map((d) =>
                    MoneyUtils.moneyToDb(MoneyUtils.dealEmptyToZero(d.inWorkCosts))
                ),
                inWorkCostPayTemplateCodes: _this.tableData.map((d) => d.payTypeCode || ' '),

                deptGroupCode: _this.form.deptGroupCode,
                deptCode: _this.form.deptCode,
                remark: _this.form.remark,
            };
            UrlUtils.PatchRemote(
                this,
                this.url.save,
                _params,
                null,
                () => {
                    _this.goBackAndReload();
                },
                (err) => {
                    _this.$message.error(err || '发卡失败');
                }
            );
        },
        deleteRow(index, rows, row) {
            rows.splice(index, 1);
        },
        handleQueryMember() {
            const _this = this;
            const _params = {
                params: this.mobile,
                paramsSerializer: function (data) {
                    return data.map((d) => 'mobile=' + d).join('&');
                },
            };
            Util.queryTable(this, this.url.queryMember, _params, (data) => {
                (async () => {
                    await data.data.forEach((v, i) => {
                        v.memberName = v.name;
                        v.memberCodes = v.code;
                        v.mobile = this.mobile[i];
                        v.cardType = '';
                        v.inWorkCosts = 0;
                        v.inWorkCostPayTemplateCodes = '';
                        v.status = '';
                    });
                    _this.tableData = data.data;
                })();
            });
        },
        handleQueryCard(row, index) {
            if (row.cardPrintNos.trim()) {
                const params = {
                    params: {
                        cardPrintNo: row.cardPrintNos.trim(),
                        deptGroupCode: this.form.deptGroupCode,
                    },
                };
                Util.queryTable(this, this.url.queryCard, params, (data) => {
                    data.data = data.data || [];
                    if (data.data.length > 0) {
                        row.cardType = data.data[0].cardType;
                        row.status = data.data[0].status;
                    } else {
                        this.$message.error('会员卡信息无效');
                        row.cardPrintNos = '';
                        row.cardType = '';
                        row.status = '';
                    }
                });
            }
        },
        handleQueryPayType() {
            const _this = this;
            if (_this.form.deptCode) {
                const _params = { params: { enable: true, deptCode: _this.form.deptCode } };
                Util.queryTable(_this, _this.url.queryPayType, _params, (data) => {
                    data.data = data.data || [];
                    _this.meta.payType = data.data;
                });
            }
        },
    },
    filters: {
        status(val) {
            let rst = '';
            if (val === 0) {
                rst = '待售';
            } else if (val === 1) {
                rst = '正常';
            } else if (val === 2) {
                rst = '丢失';
            } else if (val === 3) {
                rst = '作废';
            }
            return rst;
        },
        cardType(cardType) {
            if (typeof cardType == 'undefined') {
                return '';
            }
            if (cardType == 'chip') {
                return '芯片卡';
            }
            if (cardType == 'symbol') {
                return '符号卡';
            }
        },
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}

.el-form-item {
    width: auto;
}
</style>
